import { GoToResult } from './GoToResult';
import { cloneDeep, isEqual } from 'lodash';
import {
  computed, markRaw, onMounted, reactive, ref,
} from 'vue';
import LocalStorageService from '@/utils/LocalStorageService';
import type { GoToResultRecentKey } from './GoToResult';
import type { Reactive, Ref } from 'vue';
import type { Section } from './Section';

const goToRecentSelections = 'jcGoToRecentSelections_v2';
const maxRecentClickTracked = 10;

export interface RecentsSection extends Section {
  add: (newItem: GoToResult) => void;
}

/**
 * A composable to encapsulate logic around loading, saving and maintaining the list of
 * recently clicked search results. The list is pruned so that only recents that match
 * the current search results are displayed as those results are updated.
 *
 * @param input The current input, for handling empty state a little differently.
 * @param dataDrivenSections All sections that are the source of recent entries, for loading
 *   and being reactive to.
 * @returns A Section representing the recent list, and a function for adding to it.
 */
export function useRecents(
  input: Ref<string>,
  dataDrivenSections: Reactive<Section>[],
) {
  const maxVisible = 3;
  const maxVisibleNoSearch = 5;
  const clickHistory: Ref<GoToResult[]> = ref([]);

  // Load in the list of recently clicked items from local storage
  onMounted(async () => {
    const recentKeys = JSON.parse(LocalStorageService.getItem(goToRecentSelections) || '[]') || [];
    const history = await Promise.all(recentKeys.map((recentKey: GoToResultRecentKey) => {
      for (const section of dataDrivenSections) {
        // Only one of the sections will recognize this key. The rest will return undefined.
        const result = section.provider?.lookup(recentKey);
        if (result) {
          return result;
        }
      }
      return null;
    }));

    // Filter out any recents that could not be found for whatever reason
    // (deleted user/system, old page, etc.)
    clickHistory.value = history.filter(Boolean);
  });

  // Add an item to the recent list, deduping and keeping a max amount
  const add = (newItem: GoToResult) => {
    clickHistory.value = clickHistory.value.filter(
      recentItem => !isEqual(recentItem.recentKey, newItem.recentKey),
    );
    clickHistory.value.unshift(cloneDeep(newItem));
    clickHistory.value.splice(maxRecentClickTracked);

    const keys = clickHistory.value.map(item => item.recentKey);
    LocalStorageService.setItem(goToRecentSelections, JSON.stringify(keys));
  };

  // Reactively keep the visible recent updated based on the results of the
  // data driven sections
  const visibleResults = computed((): GoToResult[] => {
    if (input.value === '') {
      return clickHistory.value
        .map(recentItem => cloneDeep(recentItem))
        .slice(0, maxVisibleNoSearch);
    }

    const matching: GoToResult[] = clickHistory.value.map(recentItem => {
      for (const section of dataDrivenSections) {
        const result = section.allResults.find(
          (item: GoToResult) => isEqual(recentItem.recentKey, item.recentKey),
        );
        if (result !== undefined) {
          return cloneDeep(result);
        }
      }
      return null;
    }).filter(Boolean) as GoToResult[];

    const slicedRecents = matching.slice(0, maxVisible);
    return markRaw(slicedRecents);
  });

  const recentsSection = reactive({
    title: 'Recent',
    visibleResults,
    add,
  } as RecentsSection);

  return recentsSection;
}
