<template>
  <teleport to="body">
    <ModalStructure
      ref="modal"
      class="gotoModal"
      :showCloseButton="false"
      :showModal="showModal"
      @close="handleModalClosed"
    >
      <template #header>
        <slot name="header" />
      </template>
      <template #body>
        <slot name="body" />
      </template>
      <template #footer>
        <slot name="footer" />
      </template>
    </ModalStructure>
  </teleport>
</template>

<script>
import { ModalStructure } from '@jumpcloud/ui-components';
import { toRefs } from 'vue';
import { useVisibilityListener } from './useVisibilityListener';

export default {
  name: 'GoToModalWrapper',

  components: {
    ModalStructure,
  },

  props: {
    showModal: Boolean,
  },

  emits: ['close'],

  setup(props, { emit }) {
    const handleModalClosed = () => emit('close');

    const onKeyDown = (event) => {
      switch (event.key) {
        case 'Escape':
          // Prevent clearing the input before the modal visually disappears
          event.preventDefault();
          handleModalClosed();
          break;
        default:
      }
    };

    const { showModal } = toRefs(props);
    const { onVisible, onHidden } = useVisibilityListener(showModal);

    onVisible(() => {
      window.addEventListener('keydown', onKeyDown, true);

      // Set the modal top margin below the intercom banner if its present. Most modals are
      // centered vertically so this isn't a problem, but our top alignment means the banner can
      // cover the modal.
      const intercomBanner = document.querySelector('iframe[name="intercom-banner-frame"]');
      if (intercomBanner) {
        const bannerHeight = intercomBanner.offsetHeight;
        document.documentElement.style.setProperty('--goto-modal-margin-top', `${bannerHeight + 10}px`);
      }
    });

    onHidden(() => {
      window.removeEventListener('keydown', onKeyDown, true);
    });

    return {
      handleModalClosed,
    };
  },
};
</script>

<style scoped>
.gotoModal :deep(.body) {
  max-height: min(500px, calc(100vh - 200px));
  padding-top: var(--modal-padding);
}

.gotoModal :deep(div[class*="ModalContainer__modal"]) {
  margin-top: var(--goto-modal-margin-top, 60px);
}

.gotoModal :deep(div[class*="ModalContentStructure__modalContent"]) {
  --modal-padding: var(--jc-spacer);

  grid-gap: 0;
}

.gotoModal :deep(footer[class*="ModalContentStructure__footer"]) {
  border-top: var(--jc-border);
  padding-left: var(--jc-spacer-medium);
}

.gotoModal {
  align-items: flex-start!important;  /* overwrite default modal alignment */

  --modal-width: 500px;
}
</style>
