import { GoToResult } from './GoToResult';
import { ref, watch } from 'vue';
import { useVisibilityListener } from './useVisibilityListener';
import type { Ref } from 'vue';

/**
 * A composable that wraps all the logic for managing which result is selected.
 * In particular, this is helpful to encapsulate the keyboard shortcuts that
 * can be used to navigate forward and backward in the result list, including
 * wrapping around when needed.
 *
 * @param visibleResultCount The total number of results visible, used for wrapping.
 * @param showModal Whether the modal is visible or not, for key registration.
 * @returns An object containing:
 *  - `isSelected`: A function to check if an item is the selected item.
 *  - `set`: Set the selected index, for example, for example, on mouseover.
 *  - `reset`: Reset the index back to zero, for example, on modal open.
 *  - `topElementRef`: For the caller to assign to the top most element for scrolling.
 *  - `visibleElementRefs`: For the caller to assign to visible elements for scrolling
 *       and selection.
 */
export function useSelectedIndex(
  visibleResultCount: Ref<number>,
  showModal: Ref<boolean>,
) {
  const selectedIndex = ref(0);
  const topElementRef = ref<HTMLElement | null>(null);
  const visibleElementRefs = ref<HTMLElement[]>([]);

  watch(selectedIndex, (newValue) => {
    let element;
    let block = 'nearest';

    // Scroll all the way to the top so the section header can be seen
    if (newValue === 0) {
      element = topElementRef.value;
      block = 'start';
    } else {
      element = visibleElementRefs.value[newValue];
    }

    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: block as ScrollLogicalPosition,
      });
    }
  });

  const onKeyDown = (event: KeyboardEvent) => {
    const wrapIndex = (newIndex: number) => {
      const maxIndex = visibleResultCount.value;
      return (newIndex + maxIndex) % maxIndex;
    };

    switch (event.key) {
      case 'ArrowUp':
        event.preventDefault();
        selectedIndex.value = Math.max(selectedIndex.value - 1, 0);
        break;
      case 'ArrowDown':
        event.preventDefault();
        selectedIndex.value = Math.min(selectedIndex.value + 1, visibleResultCount.value - 1);
        break;
      case 'Tab':
        if (event.shiftKey) {
          selectedIndex.value = wrapIndex(selectedIndex.value - 1);
        } else {
          selectedIndex.value = wrapIndex(selectedIndex.value + 1);
        }
        break;
      case 'Enter': {
        // Simulate a click so all the same code runs
        visibleElementRefs.value[selectedIndex.value].click();
        break;
      }
      default:
    }
  };

  const { onVisible, onHidden } = useVisibilityListener(showModal);

  onVisible(() => {
    window.addEventListener('keydown', onKeyDown, true);
  });

  onHidden(() => {
    window.removeEventListener('keydown', onKeyDown, true);
  });

  const set = (item: GoToResult) => {
    if (selectedIndex.value !== item.visibleIndex) {
      selectedIndex.value = item.visibleIndex;
    }
  };

  const isSelected = (item: GoToResult) => item.visibleIndex === selectedIndex.value;

  const reset = () => {
    selectedIndex.value = 0;
  };

  const get = () => selectedIndex.value;

  return {
    isSelected,
    get,
    set,
    reset,
    topElementRef,
    visibleElementRefs,
  };
}
