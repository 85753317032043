import { type AxiosInstance, createJcApiAxiosInstance } from '@jumpcloud-ap/axios-setup';

export type GrowthDataObject = {
  onboardingState: OnboardingState;
};

export type OnboardingState = {
  selectedUseCase: string | '';
};

export interface UserResponse {
  adminGrowthData?: GrowthDataObject;
  sessionCount?: number;
}

class UserApi {
  private baseUrl = '/api/users/getSelf';
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = createJcApiAxiosInstance();
  }

  async getSelf(): Promise<UserResponse> {
    const response = await this.axiosInstance.get<UserResponse>(this.baseUrl);

    return response?.data;
  }
}

export default new UserApi();
