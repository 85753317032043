<template>
  <InputSearch
    v-model="input"
    :autofocus="true"
    :focus="true"
    placeholder="Find pages, features, users and devices"
    @update:model-value="onInput"
  />
</template>

<script>
import { InputSearch } from '@jumpcloud/ui-components';
import { debounce } from 'lodash';
import { ref, toRefs } from 'vue';
import { useInputRecorder } from './useInputRecorder';
import { useVisibilityListener } from './useVisibilityListener';

const inputDebounceTimeMS = 400;

export default {
  name: 'GoToModalInput',

  components: {
    InputSearch,
  },

  props: {
    resultCount: {
      type: Number,
      default: 0,
    },
    showModal: Boolean,
  },

  emits: ['update:input'],

  setup(props, { emit }) {
    const { showModal, resultCount } = toRefs(props);
    const input = ref('');

    useInputRecorder(input, showModal, resultCount);

    const onInputDebounced = () => {
      emit('update:input', input.value);
    };

    const debouncedOnInput = debounce(onInputDebounced, inputDebounceTimeMS);

    const onInput = (contents) => {
      input.value = contents;
      debouncedOnInput();
    };

    const { onVisible } = useVisibilityListener(showModal);
    onVisible(() => {
      input.value = '';
      onInputDebounced();
    });

    return {
      input,
      onInput,
    };
  },
};
</script>

<style scoped>
:deep(input) {
  font-size: var(--jcBodyLarge);
}
</style>
