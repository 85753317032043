import { notificationModuleName as notificationModule } from '@jumpcloud-ap/notifications';
import AlertStatsApi from '@/api/AlertStatsApi';

const initialState = () => ({
  openAlerts: 0,
});

export default {
  namespaced: true,

  state: initialState(),

  actions: {
    async fetchOpenAlertsCount({ commit, dispatch }) {
      try {
        const queryParams = {
          'groupBy[]': 'GROUP_BY_STATUS',
        };
        const alertStats = await AlertStatsApi.getAlertStats(queryParams);
        let alertsCount = 0;
        if (alertStats.context.GROUP_BY_STATUS) {
          const openAlertCount = alertStats.context.GROUP_BY_STATUS.find(
            groupByStatusCounts => groupByStatusCounts.status === 'ALERT_STATUS_OPEN');
          alertsCount = openAlertCount?.count ?? 0;
        }
        commit('SET_OPEN_ALERTS_COUNT', alertsCount);
      } catch (e) {
        dispatch(`${notificationModule}/triggerErrorNotification`, {
          title: 'Unable to retrieve notifications',
        }, { root: true });
      }
    },
  },

  mutations: {
    SET_OPEN_ALERTS_COUNT(state, count) {
      state.openAlerts = count;
    },
  },
};
