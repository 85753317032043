import LocalStorageService from '@/utils/LocalStorageService';
import UserApi from '@/api/UserApi';

const navPanelSectionsCollapsed = 'jcNavPanelSectionsCollapsed';

export default {
  namespaced: true,

  actions: {
    async fetchUserSettings() {
      const resp = await UserApi.getSelf();
      if (resp) {
        const hasSelectedUseCase = !!resp?.growthData?.onboardingState?.selectedUseCase;

        // Set nav panel state on third login for users who have selected a use case
        if (hasSelectedUseCase && resp.sessionCount === 3) {
          const sectionCollapseState = JSON.parse(
            LocalStorageService.getItem(navPanelSectionsCollapsed),
          );
          for (const key in sectionCollapseState) {
            if (typeof sectionCollapseState[key] === 'boolean' && sectionCollapseState[key] === true) {
              sectionCollapseState[key] = false;
            }
          }
          LocalStorageService.setItem(
            navPanelSectionsCollapsed, JSON.stringify(sectionCollapseState),
          );
        }
      }
    },
  },
};
