<template>
  <Nudge
    v-if="showAlertsSideNavNudge"
    class="addSideNavAlertsInfoNudge"
    placement="right-end"
    :showNudge="showAlertsSideNavNudge"
    showOverlay
    targetSelector="#NavPanelLink__navLink-Alerts"
    title="Access alerts from left nav menu"
    @close="closeNudge"
  >
    <template #body>
      <ParagraphBody>
        You can now access Alerts directly from the left navigation to quickly
        customize and manage alerts.
      </ParagraphBody>
    </template>

    <template #footer>
      <Button
        class="button"
        :data-test-id="$testId('dontShowAgain')"
        text="Don't show again"
        @click="dontShowAgain"
      />
    </template>
  </Nudge>
</template>

<script>
import {
  Button, Nudge, ParagraphBody,
} from '@jumpcloud/ui-components';
import LocalStorageService from '@/utils/LocalStorageService';

const alertsSideNavNudge = 'jcSideNavAlertsInfoNudge';

export default {
  name: 'AlertsSideNavNudge',

  components: {
    Button,
    Nudge,
    ParagraphBody,
  },

  emits: ['scrollToAlertsSideNav'],

  data() {
    return {
      isDismissed: false,
      isPageLoaded: false,
    };
  },

  computed: {
    showAlertsSideNavNudge() {
      const isAlertPage = this.$route.path === '/alerts';
      const isNavNudgeKeySet = LocalStorageService.getItem(alertsSideNavNudge);

      // emit an event to the parent component to scroll to alert widget if conditions are met
      if (isAlertPage && !isNavNudgeKeySet && !this.isDismissed) {
        this.$emit('scrollToAlertsSideNav');
      }

      const result = isAlertPage
          && !this.isDismissed
          && this.isPageLoaded
          && !isNavNudgeKeySet;

      return result;
    },
  },

  async mounted() {
    setTimeout(() => { this.isPageLoaded = true; }, 1500);
  },

  methods: {
    closeNudge() {
      this.isDismissed = true;
    },

    dontShowAgain() {
      LocalStorageService.setItem(alertsSideNavNudge, true);
      this.closeNudge();
    },
  },
};
</script>

<style scoped>

.addSideNavAlertsInfoNudge {
  --nudge-width: 30rem;
}

.button {
  margin-left: -1rem;
  width: 50%;
}
</style>
