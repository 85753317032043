import { useUiEvents } from './useUiEvents';
import { useVisibilityListener } from './useVisibilityListener';
import type { Ref } from 'vue';

const maxRecordedInputs = 10;

/**
 * A composable to capture an input event when it might otherwise not get captured
 * because it did not lead to an explicit action like a click or close. Currently,
 * this means we capture it when they hit 'backspace' and go to enter something new.
 *
 * Importantly, we implement some throttling so that we don't send too many events.
 *
 * @param input Reference to the current input of the modal.
 * @param showModal Whether or not the modal is visible, or key registration.
 * @param value An arbitrary value to include along with the event. As of original
 *   writing, this was the total result count from this search string.
 */
export function useInputRecorder(
  input: Ref<string>,
  showModal: Ref<boolean>,
  value: Ref<number>,
) {
  let lastCapturedInput = '';
  let numRecordedInputs = 0;

  const { triggerInputEnteredEvent } = useUiEvents(input);

  const recordInput = () => {
    // No need to record if the input is a substring of (or equal to)
    // our most recent capture
    if (lastCapturedInput.startsWith(input.value)) {
      return;
    }

    // Just in case this blows up segment events, we set a max for safety
    if (numRecordedInputs >= maxRecordedInputs) {
      return;
    }

    lastCapturedInput = input.value;
    numRecordedInputs += 1;

    triggerInputEnteredEvent(numRecordedInputs, value.value.toString());
  };

  const onKeyDown = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'Backspace':
        // If they are pressing backspace then it's possible they didn't find
        // what they were looking for, so let's capture this search string at
        // its fullest.
        recordInput();
        break;
      default:
    }
  };

  const { onVisible, onHidden } = useVisibilityListener(showModal);

  onVisible(() => {
    window.addEventListener('keydown', onKeyDown, true);
  });

  onHidden(() => {
    window.removeEventListener('keydown', onKeyDown, true);
  });
}
