<template>
  <div>
    <CountBadge
      ariaLabel="Alerts Badge"
      :data-test-id="$testId('badge')"
      :isHidden="!openAlerts"
      isFixed
      isTiny
      :offsetX="-1"
      status="error"
    >
      <Icon
        icon="notifications"
        :scale="1"
      />
    </CountBadge>
  </div>
</template>

<script>
import { CountBadge, Icon } from '@jumpcloud/ui-components';
import { eventBus } from '@jumpcloud-ap/event-bus';

export default {
  name: 'AlertsIconComponent',

  components: {
    CountBadge,
    Icon,
  },

  data() {
    return {
      openAlerts: 0,
    };
  },

  mounted() {
    eventBus.subscribe('alerts-main-header-button:count-updated', this.handleAlertsCountUpdated);
  },

  unmounted() {
    eventBus.unsubscribe('alerts-main-header-button:count-updated', this.handleAlertsCountUpdated);
  },

  methods: {
    handleAlertsCountUpdated(updatedAlertsCount) {
      this.openAlerts = updatedAlertsCount;
    },
  },
};
</script>

<style scoped>
.button {
  --icon-button-spacing: theme('spacing.sm');
}
</style>
