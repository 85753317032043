import { CollectionApi } from '@jumpcloud-ap/collection-api';
import { createJcApiAxiosInstance } from '@jumpcloud-ap/axios-setup';

class AlertStatsApi extends CollectionApi {
  constructor() {
    super('/api/v2/alerts-stats', {
      skip: 0,
      limit: 50,
    });

    this.axiosInstance = createJcApiAxiosInstance();
  }

  async getAlertStats(queryParams) {
    const resp = await this.axiosInstance.get('/api/v2/alerts-stats', { params: queryParams });
    return resp?.data || {};
  }
}

export default new AlertStatsApi();
