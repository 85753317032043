/**
 * This file contains a hand-curated database of JumpCloud functionality that the
 * GoToModal component will load in and index in order to provide
 * quick navigation capabilities.
 *
 * It is hand-curated for now to prove the concept, but eventually registration
 * should be distributed throughout the UI route registration code base so
 * that a central list does not need to be maintained.
 * See: https://jumpcloud.atlassian.net/browse/ARCH-192
 */

import { GoToResult } from './GoToResult';
import MiniSearch from 'minisearch';
import iconAccount from '@/img/icons/nav/icon-nav-account.svg';
import iconActiveDirectory from '@/img/icons/nav/icon-nav-activedirectory.svg';
import iconAlerts from '@/img/icons/nav/icon-nav-alerts.svg';
import iconCommands from '@/img/icons/nav/icon-nav-commands.svg';
import iconConditionalList from '@/img/icons/nav/icon-nav-conditionallist.svg';
import iconConditionalPolicies from '@/img/icons/nav/icon-nav-conditionalpolicies.svg';
import iconConfigurationGroups from '@/img/icons/nav/icon-nav-configuration-groups.svg';
import iconDeviceGroups from '@/img/icons/nav/icon-nav-device-groups.svg';
// import iconDeviceTrust from '@/img/icons/nav/icon-nav-device-trust.svg';
import iconDevices from '@/img/icons/nav/icon-nav-devices.svg';
import iconDirectories from '@/img/icons/nav/icon-nav-directories.svg';
import iconDirectoryInsights from '@/img/icons/nav/icon-nav-insights-directory.svg';
import iconGetStarted from '@/img/icons/nav/icon-nav-discover.svg';
import iconHome from '@/img/icons/nav/icon-nav-home.svg';
import iconHris from '@/img/icons/nav/icon-nav-hris.svg';
import iconIdP from '@/img/icons/nav/icon-nav-idp.svg';
import iconLdap from '@/img/icons/nav/icon-nav-ldap.svg';
import iconMdm from '@/img/icons/nav/icon-nav-mdm.svg';
import iconMfa from '@/img/icons/nav/icon-nav-mfa.svg';
import iconOsManagement from '@/img/icons/nav/icon-nav-os-management.svg';
import iconPasswordManager from '@/img/icons/nav/icon-nav-password-manager.svg';
import iconPolicies from '@/img/icons/nav/icon-nav-policies.svg';
import iconRadius from '@/img/icons/nav/icon-nav-radius.svg';
import iconReports from '@/img/icons/nav/icon-nav-reports.svg';
import iconSaasManagement from '@/img/icons/nav/icon-nav-saas-management.svg';
import iconSettings from '@/img/icons/nav/icon-nav-settings.svg';
import iconSoftwareApps from '@/img/icons/nav/icon-nav-softwareApps.svg';
import iconSso from '@/img/icons/nav/icon-nav-sso.svg';
import iconUsers from '@/img/icons/nav/icon-nav-users.svg';
import type { GoToResultRecentKey } from './GoToResult';

const searchOptions = { prefix: true, fuzzy: 0.2, boost: { title: 3, subtitle: 2 } };

const pagesDatabase: GoToResult[] = [
  new GoToResult({
    title: 'Get Started',
    subtitle: '',
    keys: '',
    imageComponent: iconGetStarted,
    url: '#/getStarted',
  }),
  new GoToResult({
    title: 'Explore Checklists',
    subtitle: 'Get Started',
    keys: '',
    imageComponent: iconGetStarted,
    url: '#/getStarted/checklists',
  }),
  new GoToResult({
    title: 'Home',
    subtitle: '',
    keys: '',
    imageComponent: iconHome,
    url: '#/home',
  }),
  new GoToResult({
    title: 'Settings',
    subtitle: 'Home',
    keys: '',
    imageComponent: iconHome,
    url: '#/home/settings',
  }),
  // Omitted for now because Pricing is dependent on some complex customer logic
  // that we aren't ready to implement right now.
  // new GoToResult({
  //   title: 'Pricing',
  //   subtitle: '',
  //   keys: 'package',
  //   imageComponent: iconHome, // TODO
  //   url: '#/pricing/packages',
  // }),
  new GoToResult({
    title: 'Alerts',
    subtitle: '',
    keys: 'device health monitoring',
    imageComponent: iconAlerts,
    featureFlag: 'deviceHealthMonitoring',
    url: '#/alerts',
    emptySearchOrder: 7,
  }),
  new GoToResult({
    title: 'Alerts',
    subtitle: '',
    keys: '',
    imageComponent: iconAlerts,
    featureFlag: '!deviceHealthMonitoring',
    url: '#/notifications',
    emptySearchOrder: 7,
  }),
  new GoToResult({
    title: 'Users',
    subtitle: '',
    keys: 'staged active suspend',
    imageComponent: iconUsers,
    url: '#/users',
    emptySearchOrder: 1,
  }),
  new GoToResult({
    title: 'Settings',
    subtitle: 'Users',
    keys: '',
    imageComponent: iconUsers,
    url: '#/users/settings',
  }),
  new GoToResult({
    title: 'User Groups',
    subtitle: 'Users',
    keys: '',
    imageComponent: iconUsers,
    url: '#/groups/user',
    emptySearchOrder: 3,
  }),
  new GoToResult({
    title: 'LDAP',
    subtitle: '',
    keys: '',
    imageComponent: iconLdap,
    url: '#/ldap',
  }),
  new GoToResult({
    title: 'RADIUS',
    subtitle: '',
    keys: 'wifi',
    imageComponent: iconRadius,
    url: '#/radius',
  }),
  new GoToResult({
    title: 'SSO Applications',
    subtitle: '',
    keys: '',
    imageComponent: iconSso,
    url: '#/applications',
  }),
  new GoToResult({
    title: 'Password Manger',
    subtitle: '',
    keys: '',
    imageComponent: iconPasswordManager,
    url: '#/passwordmanager',
  }),
  new GoToResult({
    title: 'Users',
    subtitle: 'Password Manger',
    keys: '',
    imageComponent: iconPasswordManager,
    url: '#/passwordmanager/users',
  }),
  new GoToResult({
    title: 'Shared Folders',
    subtitle: 'Password Manger',
    keys: '',
    imageComponent: iconPasswordManager,
    url: '#/passwordmanager/sharedfolders',
  }),
  new GoToResult({
    title: 'Settings',
    subtitle: 'Password Manger',
    keys: '',
    imageComponent: iconPasswordManager,
    url: '#/passwordmanager/settings#exportData',
  }),
  new GoToResult({
    title: 'Devices',
    subtitle: '',
    keys: 'system apple macos android linux ios ipad agent install',
    imageComponent: iconDevices,
    url: '#/devices/list',
    emptySearchOrder: 2,
  }),
  new GoToResult({
    title: 'Overview',
    subtitle: 'Devices',
    keys: 'system apple macos android linux ios ipad',
    imageComponent: iconDevices,
    url: '#/devices/overview',
  }),
  new GoToResult({
    title: 'Settings',
    subtitle: 'Devices',
    keys: 'system password provision account login',
    imageComponent: iconDevices,
    url: '#/devices/settings',
  }),
  new GoToResult({
    title: 'Device Groups',
    subtitle: 'Devices',
    keys: 'system ',
    imageComponent: iconDeviceGroups,
    url: '#/groups/device',
    emptySearchOrder: 4,
  }),
  new GoToResult({
    title: 'Policies',
    subtitle: 'Devices',
    keys: 'system policy',
    imageComponent: iconPolicies,
    url: '#/configurations',
  }),
  new GoToResult({
    title: 'OS Patch Management',
    subtitle: 'Devices',
    keys: 'system ',
    imageComponent: iconOsManagement,
    url: '#/configurations/patch',
  }),
  new GoToResult({
    title: 'Browser Patch Management',
    subtitle: 'Devices',
    keys: 'system ',
    imageComponent: iconPolicies,
    url: '#/configurations/patch/browser',
  }),
  new GoToResult({
    title: 'Policy Groups',
    subtitle: 'Devices',
    keys: 'system policy',
    imageComponent: iconConfigurationGroups,
    url: '#/groups/configuration',
  }),
  new GoToResult({
    title: 'Commands',
    subtitle: '',
    keys: '',
    imageComponent: iconCommands,
    url: '#/commands',
    emptySearchOrder: 5,
  }),
  new GoToResult({
    title: 'Queued Commands',
    subtitle: 'Commands',
    keys: '',
    imageComponent: iconCommands,
    url: '#/commandqueues',
  }),
  new GoToResult({
    title: 'Command Results',
    subtitle: 'Commands',
    keys: '',
    imageComponent: iconCommands,
    url: '#/commandresults',
  }),
  new GoToResult({
    title: 'MDM',
    subtitle: '',
    keys: '',
    imageComponent: iconMdm,
    url: '#/mdm/apple',
    emptySearchOrder: 8,
  }),
  new GoToResult({
    title: 'Apple',
    subtitle: 'MDM',
    keys: 'vpp macos ios ipad',
    imageComponent: iconMdm,
    url: '#/mdm/apple',
  }),
  new GoToResult({
    title: 'Windows',
    subtitle: 'MDM',
    keys: '',
    imageComponent: iconMdm,
    url: '#/mdm/windows',
  }),
  new GoToResult({
    title: 'Google EMM',
    subtitle: 'MDM',
    keys: 'android',
    imageComponent: iconMdm,
    url: '#/mdm/google',
  }),
  new GoToResult({
    title: 'Windows',
    subtitle: 'Software Management',
    keys: 'chocolately',
    imageComponent: iconSoftwareApps,
    url: '#/managedSoftware/windows',
  }),
  new GoToResult({
    title: 'Apple',
    subtitle: 'Software Management',
    keys: 'macos ios ipad vpp',
    imageComponent: iconSoftwareApps,
    url: '#/managedSoftware/apple',
  }),
  new GoToResult({
    title: 'Google',
    subtitle: 'Software Management',
    keys: 'android play store',
    imageComponent: iconSoftwareApps,
    url: '#/managedSoftware/google',
  }),
  new GoToResult({
    title: 'Active Directory',
    subtitle: '',
    keys: 'AD Microsoft Domain',
    imageComponent: iconActiveDirectory,
    url: '#/directories/active-directory',
  }),
  new GoToResult({
    title: 'Cloud Directories',
    subtitle: '',
    keys: 'google m365 azure microsoft okta',
    imageComponent: iconDirectories,
    url: '#/directories/cloud-directories',
    emptySearchOrder: 9,
  }),
  new GoToResult({
    title: 'HR Directories',
    subtitle: '',
    keys: 'bamboo bob deel factorial namely paylocity personio workday',
    imageComponent: iconHris,
    url: '#/directories/hr-directories',
  }),
  new GoToResult({
    title: 'Identity Providers',
    subtitle: '',
    keys: 'okta federation idp login',
    imageComponent: iconIdP,
    url: '#/directories/identity-providers',
  }),
  new GoToResult({
    title: 'Conditional Access Policies',
    subtitle: '',
    keys: 'policy device trust network trust geolocation portal sso ldap',
    imageComponent: iconConditionalPolicies,
    url: '#/security/authnpolicies',
  }),
  new GoToResult({
    title: 'Settings',
    subtitle: 'Conditional Access Policies',
    keys: 'policy',
    imageComponent: iconConditionalPolicies,
    url: '#/security/authnpolicies',
  }),
  new GoToResult({
    title: 'IP Lists',
    subtitle: 'Conditional Access Policies',
    keys: 'policy',
    imageComponent: iconConditionalList,
    url: '#/security/iplists',
  }),
  new GoToResult({
    title: 'Device Trust',
    subtitle: '',
    keys: 'Go ios ipad apple android mdm emm protect',
    imageComponent: iconDevices,
    featureFlag: 'deviceTrust',
    url: '#/deviceTrust',
  }),
  new GoToResult({
    title: 'MFA Configurations',
    subtitle: '',
    keys: 'go totp duo protect webuathn push',
    imageComponent: iconMfa,
    url: '#/settings/mfa',
    emptySearchOrder: 10,
  }),
  new GoToResult({
    title: 'SaaS Management',
    subtitle: '',
    keys: '',
    imageComponent: iconSaasManagement,
    url: '#/saas-management/overview',
  }),
  new GoToResult({
    title: 'Applications',
    subtitle: 'SaaS Management',
    keys: '',
    imageComponent: iconSaasManagement,
    url: '#/saas-management/applications#newly_discovered',
  }),
  new GoToResult({
    title: 'Users',
    subtitle: 'SaaS Management',
    keys: '',
    imageComponent: iconSaasManagement,
    url: '#/saas-management/users',
  }),
  new GoToResult({
    title: 'Settings',
    subtitle: 'SaaS Management',
    keys: '',
    imageComponent: iconSaasManagement,
    url: '#/saas-management/settings',
  }),
  new GoToResult({
    title: 'Directory Insights',
    subtitle: 'Events',
    keys: 'audit',
    imageComponent: iconDirectoryInsights,
    url: '#/insights',
  }),
  new GoToResult({
    title: 'Login Attempts',
    subtitle: 'Events',
    keys: '',
    imageComponent: iconDirectoryInsights,
    url: '#/insights', // TODO
  }),
  new GoToResult({
    title: 'Password Changes',
    subtitle: 'Events',
    keys: '',
    imageComponent: iconDirectoryInsights,
    url: '#/insights', // TODO
  }),
  new GoToResult({
    title: 'User state changes',
    subtitle: 'Events',
    keys: '',
    imageComponent: iconDirectoryInsights,
    url: '#/insights', // TODO
  }),
  new GoToResult({
    title: 'Association Changes',
    subtitle: 'Events',
    keys: '',
    imageComponent: iconDirectoryInsights,
    url: '#/insights', // TODO
  }),
  new GoToResult({
    title: 'User create and deletes',
    subtitle: 'Events',
    keys: '',
    imageComponent: iconDirectoryInsights,
    url: '#/insights', // TODO
  }),
  new GoToResult({
    title: 'Device create and deletes',
    subtitle: 'Events',
    keys: '',
    imageComponent: iconDirectoryInsights,
    url: '#/insights', // TODO
  }),
  new GoToResult({
    title: 'Admin creates and deletes',
    subtitle: 'Events',
    keys: '',
    imageComponent: iconDirectoryInsights,
    url: '#/insights', // TODO
  }),
  new GoToResult({
    title: 'Auths to SSO',
    subtitle: 'Events',
    keys: '',
    imageComponent: iconDirectoryInsights,
    url: '#/insights', // TODO
  }),
  new GoToResult({
    title: 'Admin activity',
    subtitle: 'Events',
    keys: '',
    imageComponent: iconDirectoryInsights,
    url: '#/insights', // TODO
  }),
  new GoToResult({
    title: 'Reports',
    subtitle: '',
    keys: '',
    imageComponent: iconReports,
    url: '#/reports',
  }),
  new GoToResult({
    title: 'Stored Reports',
    subtitle: 'Reports',
    keys: '',
    imageComponent: iconReports,
    url: '#/reports/downloads',
  }),
  new GoToResult({
    title: 'Users to Devices',
    subtitle: 'Reports',
    keys: '',
    imageComponent: iconReports,
    url: '#/reports/', // TODO
  }),
  new GoToResult({
    title: 'Users to Directories',
    subtitle: 'Reports',
    keys: '',
    imageComponent: iconReports,
    url: '#/reports/', // TODO
  }),
  new GoToResult({
    title: 'Users to LDAP',
    subtitle: 'Reports',
    keys: '',
    imageComponent: iconReports,
    url: '#/reports/', // TODO
  }),
  new GoToResult({
    title: 'Users to RADIUS',
    subtitle: 'Reports',
    keys: '',
    imageComponent: iconReports,
    url: '#/reports/', // TODO
  }),
  new GoToResult({
    title: 'Users to SSO Applications',
    subtitle: 'Reports',
    keys: '',
    imageComponent: iconReports,
    url: '#/reports/', // TODO
  }),
  new GoToResult({
    title: 'Users to User Groups',
    subtitle: 'Reports',
    keys: '',
    imageComponent: iconReports,
    url: '#/reports/', // TODO
  }),
  new GoToResult({
    title: 'Browser Patch Management',
    subtitle: 'Reports',
    keys: '',
    imageComponent: iconReports,
    url: '#/reports/', // TODO
  }),
  new GoToResult({
    title: 'OS Patch Management',
    subtitle: 'Reports',
    keys: '',
    imageComponent: iconReports,
    url: '#/reports/', // TODO
  }),
  new GoToResult({
    title: 'Accounts',
    subtitle: '',
    keys: '',
    imageComponent: iconAccount,
    url: '#/account/general',
  }),
  new GoToResult({
    title: 'Payments',
    subtitle: 'Accounts',
    keys: 'billing',
    imageComponent: iconAccount,
    url: '#/account/payment',
  }),
  new GoToResult({
    title: 'Settings',
    subtitle: '',
    keys: '',
    imageComponent: iconSettings,
    url: '#/settings',
    emptySearchOrder: 6,
  }),
  new GoToResult({
    title: 'General',
    subtitle: 'Settings',
    keys: 'Contact Name Contact Email Organization ID',
    imageComponent: iconSettings,
    url: '#/settings/organization#general',
  }),
  new GoToResult({
    title: 'Customize Logo',
    subtitle: 'Settings',
    keys: 'Header Email Login',
    imageComponent: iconSettings,
    url: '#/settings/organization#logo',
  }),
  new GoToResult({
    title: 'User Portal',
    subtitle: 'Settings',
    keys: 'Read-only Session Duration Expiration Enroll',
    imageComponent: iconSettings,
    url: '#/settings/organization#userportal',
  }),
  new GoToResult({
    title: 'Administrator Management',
    subtitle: 'Settings',
    keys: 'sso google',
    imageComponent: iconSettings,
    url: '#/settings/organization#admin',
  }),
  new GoToResult({
    title: 'Password',
    subtitle: 'Settings',
    keys: 'Length Complexity Expiration Lockout',
    imageComponent: iconSettings,
    url: '#/settings/security#password',
  }),
  new GoToResult({
    title: 'Password Recovery Email',
    subtitle: 'Settings',
    keys: '',
    imageComponent: iconSettings,
    url: '#/settings/security#passwordrecoveryemail',
  }),
  new GoToResult({
    title: 'UID/GID Management',
    subtitle: 'Settings',
    keys: 'unix',
    imageComponent: iconSettings,
    url: '#/settings/security#uidgid',
  }),
  new GoToResult({
    title: 'Password Configurations',
    subtitle: 'Settings',
    keys: 'lockout',
    imageComponent: iconSettings,
    url: '#/settings/security#passwordconfig',
  }),
  new GoToResult({
    title: 'Administrators',
    subtitle: 'Settings',
    keys: '',
    imageComponent: iconSettings,
    url: '#/settings/administrators',
  }),
  new GoToResult({
    title: 'Customize Email',
    subtitle: 'Settings',
    keys: 'Template Exipration Warning',
    imageComponent: iconSettings,
    url: '#/settings/email',
  }),
  new GoToResult({
    title: 'Features',
    subtitle: 'Settings',
    keys: 'Browser Extension System Insights Directory Insights Remote Assist Survey',
    imageComponent: iconSettings,
    url: '#/settings/features',
  }),
];

pagesDatabase.forEach((e, idx) => {
  e.id = idx;
});

// Sort to a default order for when no search is supplied
pagesDatabase.sort((a, b) => {
  if (b.emptySearchOrder && a.emptySearchOrder) {
    return a.emptySearchOrder - b.emptySearchOrder;
  }

  if (b.emptySearchOrder) {
    return 1;
  }

  if (a.emptySearchOrder) {
    return -1;
  }

  return a.id - b.id;
});

const search = new MiniSearch({
  fields: ['title', 'subtitle', 'keys'], // fields to index for full-text search
  storeFields: [ // fields to return with search results
    'title',
    'subtitle',
    'imageComponent',
    'featureFlag',
    'url',
  ],
});
search.addAll(pagesDatabase);

class PagesSection {
  public lookup(recentKey: GoToResultRecentKey) : Promise<GoToResult | undefined> | undefined {
    return recentKey.type !== 'page'
      ? undefined
      : Promise.resolve(pagesDatabase.find(result => result.recentKey.id === recentKey.id));
  }

  public async updateSearchTerm(searchTerm: string): Promise<GoToResult[]> {
    if (searchTerm === '') {
      return pagesDatabase;
    }

    const searchResults = search.search(searchTerm, searchOptions);
    const results = searchResults.map(result => new GoToResult({
      id: result.id,
      title: result.title,
      subtitle: result.subtitle,
      imageComponent: result.imageComponent,
      featureFlag: result.featureFlag,
      url: result.url,
    }));

    return results;
  }
}

export default new PagesSection();
