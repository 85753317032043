import { onUnmounted, watch } from 'vue';
import type { Ref } from 'vue';

/**
 * A composable that provides lifecycle like functions for when a modal
 * becomes visible or goes hidden. Since mounted/unmounted generally only
 * happens one time, it is not sufficient for work that needs to happen
 * only when the modal becomes visible or is closed. For example, we only
 * want key listeners to be registered when the modal is visible.
 *
 * @param visible A reference to whether the modal is visible or not, for
*   listening to changes.
 * @returns An object containing:
 *  - `onVisible`: Register a function to call when the modal is displayed.
 *  - `onHidden`: Register a function to call when the modal is closed.
 */
export function useVisibilityListener(
  visible: Ref<boolean>,
) {
  type VisiblilityChangedFunc = () => void;
  let onVisibleFunc: VisiblilityChangedFunc | undefined;
  let onHiddenFunc: VisiblilityChangedFunc | undefined;

  const onVisible = (callback: VisiblilityChangedFunc) => {
    onVisibleFunc = callback;

    if (visible.value) onVisibleFunc();
  };

  const onHidden = (callback: VisiblilityChangedFunc) => {
    onHiddenFunc = callback;

    if (!visible.value) onHiddenFunc();
  };

  watch(
    visible,
    (newValue) => {
      if (newValue && onVisibleFunc) {
        onVisibleFunc();
      } else if (!newValue && onHiddenFunc) {
        onHiddenFunc();
      }
    },
  );

  onUnmounted(() => {
    if (onHiddenFunc) onHiddenFunc();
  });

  return {
    onVisible,
    onHidden,
  };
}
