function getSpecialCharacterEscapedString(exp: string) {
  const specialCharactersRegex = /[-\\^$*+?.()|[\]{}]/g;
  const slashCharacterRegex = /[/]/g;

  return exp.replace(specialCharactersRegex, '\\$&').replace(slashCharacterRegex, '/$&');
}

/**
 * Create the array of or criteria to filter on, one for each field and word in the search
 * term. e.g.:
 *
 * "email:$regex:/Term1/i",
 * "email:$regex:/Term2/i",
 * "firstname:$regex:/Term1/i",
 * "firstname:$regex:Term2/i",
 */
export function getOrSearchCriteria(searchTerm: string, fields: string[]) {
  const fixedSearchTerm = getSpecialCharacterEscapedString(searchTerm);
  const words = fixedSearchTerm.split(' ').filter(word => !!word);

  const criteria = fields
    .flatMap(field => words.map(word => `${field}:$regex:/${word}/i`));

  return criteria;
}
