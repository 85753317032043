<template>
  <div
    :class="{
      [$style.collapsed]: isCollapsed,
      [$style.navExpanded]: panelExpanded,
    }"
  >
    <button
      v-if="title"
      :class="{
        [$style.headerLink]: true,
        [$style.linkProductTour]: hasProductTourLink,
        [$style.disabled]: showProductTour
      }"
      @click="toggleNavPanelSection"
    >
      <IconChevron
        :class="$style.headerLinkIcon"
      />
      {{ title }}
    </button>
    <ul
      :class="$style.linkList"
    >
      <li
        v-for="link in links"
        :key="link.title"
        :class="$style.linkListItem"
      >
        <NavPanelLink
          :active="isActive(link.href)"
          :activeLink="activeLink"
          :data-promo-id="link.featureId"
          :data-test-id="$testId('titleButton')"
          :highlightProductTour="hasProductTourLink"
          :item="link"
          :panelExpanded="panelExpanded"
          @link-click="handleLinkClick"
        >
          <template
            v-if="isProductTourActive(link)"
            v-slot:popover
          >
            <NavPanelProductTour
              :expanded="panelExpanded"
            />
          </template>
        </NavPanelLink>
      </li>
    </ul>

    <slot name="popover" />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import IconChevron from '@/img/icons/nav/icon-nav-chevron.svg';
import LocalStorageService from '../utils/LocalStorageService';
import NavPanelLink from '@/components/NavPanelLink.vue';
import NavPanelLinkListItem from '@/components/NavPanelLinkListItem';
import NavPanelProductTour from '@/components/NavPanelProductTour.vue';

const navPanelSectionsCollapsed = 'jcNavPanelSectionsCollapsed';

export default {
  name: 'NavPanelSection',

  components: {
    IconChevron,
    NavPanelLink,
    NavPanelProductTour,
  },

  LocalStorageService,

  props: {
    activeLink: {
      type: String,
      default: '',
    },
    isActive: {
      type: Function,
      default() { return false; },
    },
    linkClickAction: {
      type: Function,
      default() { },
    },
    links: {
      type: Array,
      required: true,
      validate(links) {
        return links.every(link => link instanceof NavPanelLinkListItem);
      },
    },
    panelExpanded: Boolean,
    startExpanded: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: '',
    },
  },

  emits: ['navPanelSectionCollapsed', 'linkClick'],

  data() {
    return {
      sectionCollapsed: false,
    };
  },

  computed: {
    ...mapGetters('ProductTourModule', [
      'getProductTourActiveLink',
    ]),

    ...mapState('ProductTourModule', [
      'showProductTour',
    ]),

    hasProductTourLink() {
      return this.showProductTour && this.links.includes(this.getProductTourActiveLink);
    },

    isCollapsed() {
      return (this.sectionCollapsed && !this.showProductTour)
        || (this.showProductTour && !this.links.includes(this.getProductTourActiveLink));
    },

    isPanelLinkActive() {
      return this.links.some((link) => this.$route.path.includes(link.href.replace('#', '')));
    },
  },

  watch: {
    $route() {
      // Open section with active link
      if (this.isPanelLinkActive && this.sectionCollapsed) {
        this.toggleNavPanelSection();
      }
      this.setNavStateFromLocalStorage();
    },
  },

  mounted() {
    this.setNavStateFromLocalStorage();
  },

  methods: {
    getNavPanelState() {
      const { getItem } = LocalStorageService;
      return JSON.parse(getItem(navPanelSectionsCollapsed)) || {};
    },

    handleLinkClick(href) {
      this.$emit('linkClick', href);
    },

    isProductTourActive(link) {
      return this.showProductTour && link.title === this.getProductTourActiveLink.title;
    },

    setNavStateFromLocalStorage() {
      const navPanelState = this.getNavPanelState();
      this.sectionCollapsed = navPanelState[this.title];
    },

    toggleNavPanelSection() {
      // Disable for active Product Tour
      if (!this.showProductTour) {
        this.sectionCollapsed = !this.sectionCollapsed;

        let navPanelState = this.getNavPanelState();
        navPanelState[this.title] = this.sectionCollapsed;
        navPanelState = JSON.stringify(navPanelState);

        const { setItem } = LocalStorageService;
        setItem(navPanelSectionsCollapsed, navPanelState);

        this.$emit('navPanelSectionCollapsed', {
          title: this.title,
          collapsed: this.sectionCollapsed,
        });
      }
    },
  },
};
</script>

<style module>
@value max-height-list: 20rem;

.headerLink.headerLink {
  align-items: center;
  background: none;
  border: none;
  border-radius: var(--jc-border-radius-x-small);
  color: var(--jc-color-neutral-text-descriptive);
  display: flex;
  font-size: 12px;
  font-weight: 700;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  padding: 0 8px;
  position: relative;
  text-transform: uppercase;
  transition: all 200ms ease-in-out;
  white-space: nowrap;
}

.linkProductTour.linkProductTour {
  color: var(--jcWhite50);
}

.navExpanded .headerLink.headerLink {
  margin-bottom: 8px;
  max-height: 2rem;
  opacity: 1;
}

.headerLinkIcon {
  fill: var(--jc-color-neutral-stroke-base);
  height: 6px;
  margin-right: 6px;
  transition: transform 0.2s ease-in-out;
  width: 6px;
}

.collapsed .headerLinkIcon {
  transform: rotate(-90deg);
}

.linkList.linkList {
  list-style-type: none;
  margin: 0;
  max-height: max-height-list;
  padding: 0;
  transition: all 0.2s ease-out, max-height 200ms ease-in-out;
  width: auto;
}

.navExpanded .linkList {
  overflow: hidden;
}

.navExpanded.collapsed .linkList {
  max-height: 0;
  opacity: 0;
}

.linkListItem:last-of-type {
  margin-bottom: 0;
}

.disabled {
  pointer-events: none;
}

@media (max-width: 1024px) {
  .navExpanded .headerLink.headerLink {
    height: 0;
    opacity: 0;
  }

  .navExpanded.collapsed .linkList {
    max-height: max-height-list;
    opacity: 1;
  }
}
</style>
