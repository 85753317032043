import { markRaw } from 'vue';

export interface GoToResultRecentKey {
  type: string;
  id: string;
}

export class GoToResult {
  keys: string;
  emptySearchOrder: number;
  featureFlag: string;
  id: number = 0;
  imageComponent: string | object;
  title: string;
  subtitle: string;
  url: string;
  recentKey: GoToResultRecentKey;
  visibleIndex: number = 0;
  externalUrl: string = '';

  constructor(params: {
    keys?: string,
    emptySearchOrder?: number,
    featureFlag?: string,
    id?: number,
    imageComponent: string | object,
    title: string,
    subtitle: string,
    url?: string,
    recentKey?: GoToResultRecentKey,
    externalUrl?: string,
  }) {
    this.keys = params.keys ? params.keys : '';
    this.emptySearchOrder = params.emptySearchOrder ? params.emptySearchOrder : 0;
    this.featureFlag = params.featureFlag ? params.featureFlag : '';
    this.id = params.id != null ? params.id : -1;
    this.imageComponent = typeof params.imageComponent === 'string'
      ? params.imageComponent
      : markRaw(params.imageComponent);
    this.title = params.title;
    this.subtitle = params.subtitle;
    this.url = params.url ? params.url : '';
    this.recentKey = params.recentKey ? params.recentKey : { type: 'page', id: this.title + this.subtitle };
    this.externalUrl = params.externalUrl ? params.externalUrl : '';
  }
}
