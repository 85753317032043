import { GoToResult } from './GoToResult';
import { UiEvents } from '@jumpcloud/ui-events-vue3';
import type { Ref } from 'vue';
import type { Section } from './Section';

/**
 * A composable that wraps all event reporting to keep calling code clean
 * and make it easy to see all events that the GoTo feature sends.
 *
 * @param input The current input box value, for saving alongside the events.
 */
export function useUiEvents(
  input: Ref<string>,
) {
  const triggerInputEnteredEvent = (numRecordedInputs: number, value: string) => {
    UiEvents.triggerInputEntered({
      description: 'GoTo modal input entered',
      displayLogic: input.value,
      page: 'GoTo Modal',
      section: 'input',
      region: numRecordedInputs.toString(),
      value,
    });
  };

  const triggerLinkClickedEvent = (section: Section, item: GoToResult) => {
    UiEvents.triggerLinkClicked({
      description: 'GoTo result clicked',
      displayLogic: input.value,
      page: 'GoTo Modal',
      section: 'results',
      region: section.title,
      text: `${item.title} - ${item.subtitle}`,
      toUrl: `${item.url || ''}${item.externalUrl || ''}`,
    });
  };

  const triggerSupportFooterClickedEvent = (supportHref: string) => {
    UiEvents.triggerLinkClicked({
      description: 'GoTo support footer clicked',
      displayLogic: input.value,
      page: 'GoTo Modal',
      section: 'footer',
      region: 'footer',
      text: '',
      toUrl: supportHref,
    });
  };

  const triggerExitWithoutClickEvent = () => {
    UiEvents.triggerButtonClicked({
      description: 'GoTo modal exited without link click',
      displayLogic: input.value,
      page: 'GoTo Modal',
      section: 'n/a',
      text: '',
    });
  };

  return {
    triggerInputEnteredEvent,
    triggerLinkClickedEvent,
    triggerSupportFooterClickedEvent,
    triggerExitWithoutClickEvent,
  };
}
